import type { VideoObject } from 'schema-dts'
import type { BlockTypes } from '@etf1-interne/tf1info_types_news'
import type { FormattedVideo } from '@etf1-interne/tf1info_types_news/generics'
import type { IDataMain, IPage } from '../../types/page'

import config from '../../config'
import newsMediaOrganizationObject from './newsMediaOrganization'

import { toISOStringWithParisTimezone } from '../time'

export interface IVideoObjectData {
  video: FormattedVideo
  keywords: string[]
  image: string
  publishedAt: string
  url?: string
}

export function videoObject(data: IVideoObjectData): VideoObject | null {
  if (!data?.video) {
    return null
  }

  const video = data.video

  const copyrightYear = video.created_at ? new Date(video.created_at).getFullYear() : null
  const contentUrl = video.video_id ? `${config.baseUrl}/player/${video.video_id}/` : null

  return {
    '@type': 'VideoObject',
    name: video.title,
    caption: video.title,
    alternateName: video.specificFields.shortSummary,
    description: video.specificFields.longSummary,
    encodingFormat: 'mp4',
    keywords: data.keywords,
    genre: data.keywords,
    thumbnailUrl: data.image || `${config.domain}/images/tf1info-image-fallback-dark.jpg`,
    uploadDate: video.created_at,
    duration: `P0Y0M0DT0H0M${video.duration}S`,
    inLanguage: 'fr',
    playerType: 'HTML5',
    author: newsMediaOrganizationObject(),
    copyrightHolder: newsMediaOrganizationObject(),
    copyrightYear,
    publisher: newsMediaOrganizationObject(),
    ...(contentUrl ? { embedUrl: contentUrl, contentUrl } : {}),
    datePublished: data?.publishedAt || '',
    height: '720',
    width: video.isVertical ? '405' : '1280',
    videoQuality: 'HD',
    requiresSubscription: false,
    isAccessibleForFree: true,
    isFamilyFriendly: true,
    ...(data.url ? { url: data.url } : {}),
  }
}

export interface IVideoLiveObjectData {
  page: IPage['page']
}

export function videoObjectLive({ page }: IVideoLiveObjectData): VideoObject | null {
  if (!page?.data || !Array.isArray(page.data)) return null

  const mainSection = page.data.find((elem) => elem.key === 'main') as IDataMain
  const bodyHeaderSection = mainSection?.data?.find((elem) => elem.key === 'body-header')
  const bodySection = mainSection?.data?.find((elem) => elem.key === 'body')
  const blocks = [...(bodyHeaderSection?.data ?? []), ...(bodySection?.data ?? [])]
  const liveBlock = blocks.find((block) => block.key === 'lives')
    ?.data as BlockTypes['lives']['data']

  if (!liveBlock) return null

  const currentChannelData = liveBlock.elementList.find(
    (channel) => channel.liveId === liveBlock.liveId,
  )

  if (!currentChannelData || !currentChannelData?.availability?.available) return null
  const currentProgram = currentChannelData?.currentTimeSlot
  const currentProgramDate = currentProgram.start
    ? toISOStringWithParisTimezone(currentProgram.start)
    : new Date(Date.now()).toISOString().split('T')[0]
  const endProgramDate = currentProgram.end
    ? toISOStringWithParisTimezone(currentProgram.end)
    : new Date(Date.now()).toISOString().split('T')[0]

  return {
    '@type': 'VideoObject',
    alternateName: currentProgram.name || '',
    name: liveBlock.title || '',
    description: currentProgram.subtitle || '',
    isAccessibleForFree: true,
    thumbnailUrl:
      currentProgram.pictures.elementList[0].url ||
      `${config.domain}/images/tf1info-image-fallback-dark.jpg`,
    uploadDate: currentProgramDate,
    url: `${config.baseUrl}${page.url}`,
    publication: [
      {
        '@type': 'BroadcastEvent',
        isLiveBroadcast: true,
        startDate: currentProgramDate,
        endDate: endProgramDate,
      },
    ],
  }
}
