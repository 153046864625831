import type { ReactNode, Context } from 'react'
import { createContext } from 'react'
import type { IPage, IPageData } from '../types/page'

export interface IApiResponse {
  page?: IPageData
  subtype: string
  type: string
  ssr: IPage['ssr']
}

export type IApiResponseContext = Context<IApiResponse>

export const ApiResponseContext: IApiResponseContext = createContext({
  page: null,
  subtype: '',
  type: '',
  ssr: null,
})

export function ApiResponse({
  children,
  page,
  subtype,
  type,
  ssr,
}: {
  children?: ReactNode
  page: IPageData
  subtype: string
  type: string
  ssr: IPage['ssr']
}): JSX.Element {
  return (
    <ApiResponseContext.Provider value={{ page, subtype, type, ssr }}>
      {children}
    </ApiResponseContext.Provider>
  )
}
