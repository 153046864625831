export const HEADER_ID = 'headerid'
export const HEADLINES_ID = 'headlineid'
export const MENU_ID = 'menuid'
export const MENUMAIN_ID = 'menumainid'
export const MENUSECONDARY_ID = 'menusecondaryid'
export const SEARCH_ID = 'searchid'
export const FOOTER_ID = 'footerid'
export const MAIN_ID = 'mainid'
export const MAIN_HOMEFORYOU_ID = 'block-home-user'
export const VIDEO_MEDIA_ID = 'videoMediaid'
export const HOMEFORYOU_HELLO_ID = 'homeforyouHelloid'
export const NOTIFICATION_ID = 'notificationid'
export const FILTERMENU_ID = 'filtermenuid'

export const VIDEO_LIVE_ID = 'L_LCI'

export const ARTICLE_ICON_VIDEO = 'play'
