import type { ReactNode, Context } from 'react'
import type { PageType } from '../types/page'

import { createContext, useState } from 'react'
import { theme } from '../styles'
import { SVGIcon } from '../components/atoms/SVGIcon'
import { PAGE_TYPE_CONTENT } from '../constants/page'
import { useAmp } from 'next/amp'

export interface IToast {
  setToast: ({ title }: { title: string }) => void
}

export type IToastContext = Context<IToast>

export const ToastContext: IToastContext = createContext({
  setToast: () => null,
})

export function ToastProvider({ children }: { children?: ReactNode }): JSX.Element {
  const [isVisible, setIsVisibleToast] = useState(false)
  const [title, setTitleToast] = useState('')
  const handleOnclick = () => setIsVisibleToast(false)
  const setToast = ({ title }) => {
    setIsVisibleToast(true)
    setTitleToast(title)
  }

  return (
    <>
      <ToastContext.Provider
        value={{
          setToast,
        }}
      >
        <div className={`toast${isVisible ? '' : ' toast--hidden'} flex`} role="status">
          {isVisible && (
            <>
              <p>{title}</p>
              <button onClick={handleOnclick} title="Fermer la notication">
                <SVGIcon name="close" size={24} primaryColor={theme.cssVars.deepBlue} />
              </button>
            </>
          )}
        </div>
        {children}
      </ToastContext.Provider>
      <style jsx>{`
        .toast {
          position: fixed;
          justify-content: center;
          align-items: center;
          z-index: 1000;
          bottom: 0;
          left: 50%;
          transform: ;
          padding: 8px 24px;
          gap: 12px;
          border-radius: 24px;
          background-color: ${theme.cssVars.focusBlue};
          color: ${theme.cssVars.liveProgramBlue};
          box-shadow: 4px 4px 20px 0px rgba(0, 10, 104, 0.1);
          transition: transform 0.2s;
          transform: translate(-50%, -30px);
        }

        .toast p {
          font-family: ${theme.fonts.overpass};
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
        }

        .toast--hidden {
          width: 0;
          height: 1px;
          transition: transform 0.5s;
          transform: translate(-50%, 200px);
        }

        .toast > button {
          cursor: pointer;
          background-color: transparent;
          border: none;
        }

        .toast > button::before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
        }
      `}</style>
    </>
  )
}

export function ToastProviderWrapper({
  type,
  children,
}: {
  type: PageType
  children?: ReactNode
}): JSX.Element {
  const isAmp = useAmp()
  if (type !== PAGE_TYPE_CONTENT || isAmp) return <>{children}</>

  return <ToastProvider>{children}</ToastProvider>
}
