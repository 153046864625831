import type { ITF1AdSlot } from '../components/atoms/AdConfig'
import { devices, devicesFromViewport } from '../constants/gam'
import { getViewport } from '../hook/useViewport'

export const isAdDispayable = (formats: typeof devices[number][]) => {
  if (formats.includes('website')) return true
  const actualFormat = getViewport()
  return formats.includes(devicesFromViewport[actualFormat])
}

class Timer {
  constructor(duration: number, callback: () => void) {
    this.seconds = 0
    this.duration = duration || 0
    this.callback = callback
  }

  seconds: number
  duration: number
  callback: () => void

  start = function () {
    clearInterval(this.Interval)
    this.Interval = setInterval(() => {
      this.count()
    }, 1000)
  }
  stop = function () {
    clearInterval(this.Interval)
  }
  reset = function () {
    clearInterval(this.Interval)
    this.seconds = 0
  }
  count = function () {
    this.seconds++
    if (this.seconds === this.duration) {
      this.stop()
      this.reset()
      this.callback()
    }
  }
}

export const refreshSlot = (slot: ITF1AdSlot): void => {
  slot.setTargeting('refresh', 'true')
  googletag.cmd.push(function () {
    googletag.pubads().refresh([slot])
  })
}

export const defineSlot = (
  path: string,
  lazyLoad: boolean,
  sizes: googletag.GeneralSize,
  containerId: string,
  targets: { [key: string]: string },
  refresh: boolean,
  duration: number,
): ITF1AdSlot => {
  const slot: ITF1AdSlot = window.googletag
    .defineSlot(path, sizes, containerId)
    .addService(window.googletag.pubads())
  Object.entries(targets).forEach(([key, value]) => {
    if (value) {
      slot.setTargeting(key, value)
    }
  })
  slot.lazy = !!lazyLoad
  if (refresh) {
    slot.timer = new Timer(duration, () => refreshSlot(slot))
  }
  return slot
}
