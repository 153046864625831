import type { BlockTypes } from '@etf1-interne/tf1info_types_news'
import type { IPageData, PageType } from '../../../types/page'
import Head from 'next/head'
import config from '../../../config'
import newsArticle from '../../../helpers/structuredData/newsArticle'
import liveBlogPostingObject from '../../../helpers/structuredData/liveBlogPosting'
import { videoObjectLive, videoObject } from '../../../helpers/structuredData/videoObject'
import { getBlockFromMainBody } from '../../../helpers/blocks'
import { formatKeywords } from '../../../helpers/seoFormat'

interface ISEOStructuredData {
  page: IPageData
  type: PageType
  subtype: string
}

export function SEOStructuredData({ page, type, subtype }: ISEOStructuredData): JSX.Element {
  const structuredData = []

  if (type === 'content') {
    const liveBlogPosting = liveBlogPostingObject(page)
    if (liveBlogPosting) {
      structuredData.push(liveBlogPosting)
    }

    if (subtype === 'video') {
      const richVideoBlock = getBlockFromMainBody(page, 'rich-video')
        ?.data as BlockTypes['rich-video']['data']
      const video = richVideoBlock?.video
      const keywords: string[] = formatKeywords(page.seo.newsMetaKeywords)
      const image = page?.image?.url || ''

      structuredData.push(
        videoObject({
          video,
          keywords,
          image,
          publishedAt: page.createdAt,
          url: `${config.baseUrl}${page.url}`,
        }),
      )
    } else {
      structuredData.push(newsArticle(page))
    }
  }

  structuredData.push(videoObjectLive({ page }))

  const filteredStructuredData = structuredData.filter(Boolean)

  if (!filteredStructuredData.length) return null

  const data = filteredStructuredData.map((data) => {
    return {
      '@context': 'https://schema.org',
      ...data,
    }
  })

  return (
    <Head>
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: JSON.stringify(data, null, config.environment === 'production' ? null : 4),
        }}
      />
    </Head>
  )
}
