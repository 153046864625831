import type { LiveBlogPosting } from 'schema-dts'
import type { BlockTypes } from '@etf1-interne/tf1info_types_news'
import type { IPage, IDataMain, IDataHeader } from '../../types/page'
import type { IHeader } from '../../components/organisms/Header'
import { formatKeywords } from '../seoFormat'
import eventObject from './event'
import associatedMediaObject from './associatedMedia'
import blogPostingObject from './blogPosting'
import creativeWorkObject from './creativeWork'
import imageObject from './imageObject'
import newsMediaOrganizationObject from './newsMediaOrganization'
import { journalistPersonObject } from './person'
import postalAddressObject from './postalAddress'
import config from '../../config'

export default function liveBlogPostingObject(page: IPage['page']): LiveBlogPosting | null {
  const mainSection = page.data?.find((elem) => elem.key === 'main') as IDataMain
  const bodySection = mainSection?.data?.find((elem) => elem.key === 'body')
  const multiliveBlock = bodySection?.data?.find((elem) => elem.key === 'multi-live')

  if (!multiliveBlock) {
    return null
  }

  const multiLiveData = multiliveBlock.data as BlockTypes['multi-live']['data']

  const getMainTopicName = () => {
    const headerData = page?.data?.find((elem) => elem.key === 'header') as IDataHeader
    const headerBlock = headerData?.data?.find((elem) => elem.key === 'header-navigation')
      ?.data as IHeader

    return headerBlock?.topic?.title ? headerBlock.topic.title : page.h1
  }

  const keywords: string[] = formatKeywords(page.seo.newsMetaKeywords)
  const associatedMedia = associatedMediaObject(page, keywords)

  const liveEndDate: string = multiLiveData.liveState
    ? ''
    : multiLiveData.elementList?.[0]?.date ?? ''

  const image = imageObject({ data: page.image, preset: 'defaultArticle' })

  return {
    '@type': 'LiveBlogPosting',
    url: `${config.domain}${page.url}`,
    mainEntityOfPage: `${config.domain}${page.url}`,
    coverageStartTime: page.createdAt,
    coverageEndTime: liveEndDate,
    headline: page.h1 || '',
    alternativeHeadline: page.title || '',
    description: page.description || '',
    ...(page.articleBody ? { articleBody: page.articleBody } : {}),
    articleSection: page?.category?.name || '',
    inLanguage: 'fr',
    keywords,
    image,
    about: eventObject({
      name: getMainTopicName(),
      description: page.description || '',
      title: page.h1 || '',
      image,
      startDate: page.createdAt,
      endDate: liveEndDate,
    }),
    contentLocation: {
      '@type': 'Place',
      address: postalAddressObject(null, 'tf1'),
    },
    citation: page.tags
      .filter((tag) => tag.isVisible)
      .map(({ link, name }) =>
        creativeWorkObject({ url: link ? `${config.domain}${link}` : '', text: name }),
      ),
    author: journalistPersonObject(page.authors),
    datePublished: page.createdAt,
    dateModified: page.updatedAt,
    isAccessibleForFree: true,
    associatedMedia,
    liveBlogUpdate: multiLiveData.elementList.map((post) =>
      blogPostingObject(post, page.authors, `${config.domain}${page.url}`),
    ),
    publisher: newsMediaOrganizationObject(),
  }
}
