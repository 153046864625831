import Head from 'next/head'
import config from '../../../config'

interface ISEOItemList {
  itemLists: { position: number; url: string }[]
}

export function SEOItemLists({ itemLists }: ISEOItemList): JSX.Element {
  if (!itemLists?.length) return null

  const items = [
    {
      '@context': 'http://schema.org',
      '@type': 'ItemList',
      itemListElement:
        itemLists?.map(({ position, url }) => ({
          '@type': 'ListItem',
          position,
          url: `${config.domain}${url}`,
        })) || [],
    },
  ]

  return (
    <Head>
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: JSON.stringify(items, null, config.environment === 'production' ? null : 4),
        }}
      />
    </Head>
  )
}
