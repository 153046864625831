import type { NewsMediaOrganization, SearchAction } from 'schema-dts'
import imageObject from './imageObject'
import postalAddress from './postalAddress'

type CustomSearchAction = SearchAction & {
  'query-input': {
    '@type': string
    valueRequired: boolean
    valueName: string
  }
}

const potentialAction: CustomSearchAction = {
  '@type': 'SearchAction',
  target: 'https://www.tf1info.fr/recherche/?query={query}',
  'query-input': {
    '@type': 'PropertyValueSpecification',
    valueRequired: true,
    valueName: 'query',
  },
}

export default function newsMediaOrganizationObject(): NewsMediaOrganization {
  return {
    '@type': 'NewsMediaOrganization',
    name: 'TF1 Info',
    alternateName: 'Télévision Française 1 Info',
    parentOrganization: {
      '@type': 'Organization',
      name: 'Groupe TF1',
      foundingDate: '1987-04-16',
      url: 'https://groupe-tf1.fr/',
      sameAs: [
        'https://fr.wikipedia.org/wiki/Groupe_TF1',
        'https://www.linkedin.com/company/groupetf1',
        'https://twitter.com/GroupeTF1',
        'https://www.wikidata.org/wiki/Q2412906',
      ],
    },
    url: 'https://www.tf1info.fr',
    foundingDate: '2022-01-24',
    logo: imageObject({ preset: 'logo' }),
    address: postalAddress(null, 'tf1'),
    sameAs: [
      'https://www.facebook.com/TF1Info/',
      'https://twitter.com/TFIINFO',
      'https://news.google.com/publications/CAAqBwgKMNDhsQEw-fUJ',
      'https://www.wikidata.org/wiki/Q111191650',
      'https://www.youtube.com/channel/UCsrPUA0ZSDCNZC6wyRlR7ZA',
      'https://www.tiktok.com/@tf1info',
      'https://www.instagram.com/tf1info/',
      'https://fr.wikipedia.org/wiki/TF1_INFO',
    ],
    masthead: 'https://www.tf1info.fr/tf1-info/redaction/',
    actionableFeedbackPolicy: 'https://www.tf1info.fr/la-mediatrice-vous-repond/',
    correctionsPolicy: 'https://www.tf1info.fr/tf1-info/charte-editoriale/',
    publishingPrinciples: 'https://www.tf1info.fr/tf1-info/charte-editoriale/',
    ethicsPolicy: 'https://www.tf1info.fr/tf1-info/charte-editoriale/',
    verificationFactCheckingPolicy:
      'https://www.tf1info.fr/tf1-info/les-verificateurs-fact-checking/',
    potentialAction,
  }
}
