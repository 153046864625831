import type { PostalAddress } from 'schema-dts'

export interface IPostalAddressData {
  addressCountry?: string
  addressLocality?: string
  addressRegion?: string
  postalCode?: string
  streetAddress?: string
}

const presets: Record<string, IPostalAddressData> = {
  tf1: {
    addressCountry: 'France',
    addressLocality: 'Boulogne-Billancourt',
    postalCode: '92100',
    streetAddress: '1 Quai du Point du Jour',
  },
}

export default function postalAddressObject(
  obj: IPostalAddressData,
  preset?: keyof typeof presets,
): PostalAddress | null {
  const data: IPostalAddressData = obj || presets?.[preset]

  if (!data) {
    return null
  }

  return {
    '@type': 'PostalAddress',
    ...(data.addressCountry && { addressCountry: data.addressCountry }),
    ...(data.addressRegion && { addressRegion: data.addressRegion }),
    ...(data.addressLocality && { addressLocality: data.addressLocality }),
    ...(data.postalCode && { postalCode: data.postalCode }),
    ...(data.streetAddress && { streetAddress: data.streetAddress }),
  }
}
