import { Context, useCallback, PropsWithChildren } from 'react'
import type { User } from '../types/user'
import type { GIGYA_LOADING_STATUS, GigyaResponse } from '../types/gigya'
import type { IuseScript } from '../hook/useScript'
import { createContext, useEffect, useState, useRef } from 'react'
import { usePathname, useSearchParams } from 'next/navigation'
import { useScript } from '../hook/useScript'
import { fetchDeleteAccount, setupGamCookies } from '../api/client/ssr'
import { GIGYA_SCRIPT_ID } from '../constants/scripts'
import { HOME_URLS } from '../constants/home'
import { pickByString } from '../helpers/object'
import { getAccountInfo, getGigyaJWT, setAccountInfo } from '../helpers/gigya'
import { omit } from '../helpers/omit'
import { getCookie } from '../helpers/cookies'
import {
  GIGYA_LOADING_STATUS_ERROR,
  GIGYA_LOADING_STATUS_LOADED,
  GIGYA_LOADING_STATUS_LOADING,
  GIGYA_RESPONSE_OK,
  SUBSCRIBED_BOOKMARK_PARAM,
  SUBSCRIBED_NEWSLETTER_PARAM,
} from '../constants/gigya'

export type IGigyaAuth = PropsWithChildren

export type IGigyaContext = Context<{
  isGigyaReady: boolean
  user?: User
  jwt?: string
  refreshToken: () => void
  updateUser: (user: Partial<User>) => Promise<GigyaResponse>
  disconnectUser: () => void
  deleteAccount: () => Promise<void>
  hasError: boolean
  downloadUserData: () => void
}>

export const GigyaContext: IGigyaContext = createContext({
  isGigyaReady: false,
  user: null,
  jwt: '',
  refreshToken: () => null,
  updateUser: () => null,
  disconnectUser: () => null,
  deleteAccount: () => null,
  hasError: false,
  downloadUserData: () => null,
})

export function GigyaAuth({ children }: IGigyaAuth): JSX.Element {
  const { isScriptLoaded, hasScriptError, isScriptActive }: IuseScript = useScript({
    id: GIGYA_SCRIPT_ID,
  })
  const [gigyaStatus, setGigyaStatus] = useState<GIGYA_LOADING_STATUS>(GIGYA_LOADING_STATUS_LOADING)
  const [user, setUser] = useState<User>(null)
  const [jwt, setJWT] = useState<string>('')
  const previousUrl = useRef<string>()
  const asPath = usePathname()
  const searchParams = useSearchParams()

  const queryParams =
    searchParams.has(SUBSCRIBED_NEWSLETTER_PARAM) || searchParams.has(SUBSCRIBED_BOOKMARK_PARAM)
      ? new URLSearchParams(
        pickByString({
          [SUBSCRIBED_NEWSLETTER_PARAM]: searchParams.get(SUBSCRIBED_NEWSLETTER_PARAM),
          [SUBSCRIBED_BOOKMARK_PARAM]: searchParams.get(SUBSCRIBED_BOOKMARK_PARAM),
        }),
      )
      : null

  const originUrl = getCookie('originUrl')
  const closeUrl = originUrl
    ? `${originUrl}${queryParams ? `?${queryParams.toString()}` : ''}`
    : '/'

  const setUserInfo = useCallback(
    function setUserInfoCallback(response: GigyaResponse | User) {
      const { UID, UIDSignature, signatureTimestamp, profile, data, preferences } = response
      setUser({ UID, UIDSignature, signatureTimestamp, profile, preferences, data })
    },
    [setUser],
  )

  function redirect(logged?: boolean) {
    if (!searchParams.has('device')) {
      if (searchParams.has('redirect') && searchParams.get('redirect')) {
        window.location.href = `${searchParams.get('redirect')}`
        return
      }
      if (!logged && HOME_URLS[1] === asPath) {
        window.location.href = '/'
        return
      }

      if (previousUrl.current) {
        window.location.href = previousUrl.current
      } else {
        window.location.href = closeUrl
      }
    }
  }

  function disconnectUser() {
    window.gigya.accounts.logout({
      callback: () => {
        setUser(null)
        redirect(false)
      },
    })
  }

  async function deleteAccount() {
    const isApp = !!searchParams.has('device')

    try {
      const body = await fetchDeleteAccount(user)

      const isAccountRemoved = body.statusReason === 'OK'

      if (isAccountRemoved) {
        setUser(null)
        isApp
          ? window?.ReactNativeWebView?.postMessage(
            JSON.stringify({ action: 'DELETE_USER', status: 'SUCCESS' }),
          )
          : (window.location.href = `/compte/suppression-du-compte/`)
        return
      }
      console.error(body.errorMessage)
      isApp
        ? window?.ReactNativeWebView?.postMessage(
          JSON.stringify({ action: 'DELETE_USER', status: 'ERROR' }),
        )
        : (window.location.href = `/compte/`)
    } catch (error) {
      console.error('error', error)
      isApp
        ? window?.ReactNativeWebView?.postMessage(
          JSON.stringify({ action: 'DELETE_USER', status: 'ERROR' }),
        )
        : (window.location.href = `/compte/`)
    }
  }

  function onLoginHandler(response: GigyaResponse) {
    setUserInfo(response)
    setupGamCookies(response)
    getGigyaJWT().then((token) => {
      setJWT(token)
    })

    // redirect only if connection is successful from connection screen
    // (not from email redirection)
    if (window && window?.location?.href?.includes('/compte/connexion/')) {
      redirect(true)
    }
  }

  const downloadUserData = async () => {
    const isApp = !!searchParams.get('device')
    if (isApp) {
      // Tells mobile app to download user info
      window?.ReactNativeWebView?.postMessage(
        JSON.stringify({ action: 'DOWNLOAD_USER_PROFILE', status: 'SUCCESS' }),
      )
    } else {
      const userData = await getAccountInfo({
        include: 'profile,data,preferences',
      })
      const fileData = omit(
        userData,
        'callId',
        'errorCode',
        'apiVersion',
        'operation',
        'UID',
        'UIDSignature',
        'requestParams',
        'status',
        'errorMessage',
        'statusMessage',
      )
      const dataStr =
        'data:text/json;charset=utf-8,' + encodeURIComponent(JSON.stringify(fileData, null, 4))
      const anchorElement = document.createElement('a')
      anchorElement.setAttribute('href', dataStr)
      anchorElement.setAttribute(
        'download',
        `tf1info-donnees-${userData.time.slice(0, 10)}` + '.json',
      )
      document.body.appendChild(anchorElement) // required for firefox
      anchorElement.click()
      anchorElement.remove()
    }
  }

  const updateUser = useCallback(
    async function updateUserMemoized(user: Partial<User>): Promise<GigyaResponse> {
      const response = await setAccountInfo(user)
      if (response.errorCode === 0) {
        const refreshedUser = await getAccountInfo({
          include: 'profile,data,preferences',
        })

        setUserInfo(refreshedUser)
      }
      return response
    },
    [setUserInfo],
  )

  const refreshUser = useCallback(
    async function updateUserCallback() {
      const [userResponse, token] = await Promise.all([
        getAccountInfo({
          include: 'profile,data,preferences',
        }),
        getGigyaJWT(),
      ])
      if (userResponse.status === GIGYA_RESPONSE_OK) {
        setUserInfo(userResponse)
      }
      setJWT(token)
      setGigyaStatus(GIGYA_LOADING_STATUS_LOADED)
    },
    [setUserInfo],
  )

  const refreshToken = useCallback(function refreshTokenCallback() {
    getGigyaJWT().then((token) => {
      setJWT(token)
    })
  }, [])

  useEffect(() => {
    if (asPath && !asPath.includes('/compte/')) {
      previousUrl.current = asPath
    }
  }, [asPath])

  useEffect(() => {
    if (isScriptLoaded) {
      window.gigya.accounts?.addEventHandlers({ onLogin: onLoginHandler })
    }
  }, [isScriptLoaded])

  useEffect(() => {
    if (hasScriptError) {
      setGigyaStatus(GIGYA_LOADING_STATUS_ERROR)
    }
    if (isScriptLoaded) {
      if (!window.gigya?.accounts?.getAccountInfo) {
        setGigyaStatus(GIGYA_LOADING_STATUS_ERROR)
      } else {
        refreshUser()
      }
    }
  }, [isScriptLoaded, hasScriptError])

  return (
    <GigyaContext.Provider
      value={{
        user,
        jwt,
        updateUser,
        refreshToken,
        disconnectUser,
        deleteAccount,
        downloadUserData,
        isGigyaReady: gigyaStatus === GIGYA_LOADING_STATUS_LOADED || !isScriptActive,
        hasError: gigyaStatus === GIGYA_LOADING_STATUS_ERROR,
      }}
    >
      {children}
    </GigyaContext.Provider>
  )
}
